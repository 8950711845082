<template>
  <div id="app" ref="app" :style="width">
    <router-view/>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import {wxTicket} from "@/api/login";

export default {
  watch: {
    '$store.state.userInfo'() {
      // this.init()
    }
  },
  data() {
    return {
      width: '',
      title: '万人添照',
    }
  },
  created() {
    const {clientWidth} = document.documentElement

    setTimeout(() => {
      if (clientWidth >= 500) {
        this.width = 'width:375px;margin: 0 auto;'
        // this.$refs.app.style.width='375px'
      }
    }, 300)

    // 页面每次刷新加载时候都会去读取localStorage里面的vuex状态
    if (localStorage.getItem("store")) {
      this.$store.replaceState(
          Object.assign(
              {},
              this.$store.state,
              JSON.parse(localStorage.getItem("store")) //这里存的是可能经过mutions处理过的state值，是最新的,所以放在最后
          )
      )
    }
    // 在页面刷新之前把vuex中的信息存到sessionStoreage
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("store", JSON.stringify(this.$store.state));
    });

    this.$root.$on('init', () => {
      this.init()
    })
  },
  mounted() {
    this.init()
    this.$store.state.paintingStart = true
  },
  beforeDestroy() {
    localStorage.setItem("store", JSON.stringify(this.$store.state));
  },
  methods: {
    init() {
      let url = 'http://wrtz.3qlab.com';
      this.url = url
      this.title = '万人添照'
      setTimeout(() => {
        this.getKeyParams()
      }, 500)
      this.wxShare()
    },
    async getKeyParams() {
      // let url = window.location.href;
    },
    offsetHeight() {
      return this.$refs.app.offsetHeight
    },
    wxShare() {
      let url = window.location.href;
      let CompanyID = JSON.parse(localStorage.getItem("CompanyID"))
      if (CompanyID) {
        url = url + '?CompanyID=' + CompanyID
      }

      wxTicket({url}).then((res) => {

        let signature = res.payload.signature;
        let noncestr = res.payload.noncestr;
        let timestamp = res.payload.timestamp;
        wx.config({
          appId: "wxa8ce8daa4ddb25f2", // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: noncestr, // 必填，生成签名的随机串
          signature: signature, // 必填，签名
          jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表
        });

        wx.ready(function () {
          //需在用户可能点击分享按钮前就先调用
          wx.updateAppMessageShareData({
            title: "天长全家福", // 分享标题
            desc: "随手一拍赢9999元大奖，天长全家福等你来拍", // 分享描述
            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl:
                "https://3qlab-wrth.oss-cn-hangzhou.aliyuncs.com/image/2024-2-1/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240201162248%20%281%29.jpg", // 分享图标
          });
        });
      });
    },
  }
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

#app {
  width: 100%;
  height: 100%;
}


div::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

div::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 0;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}

div::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: #ededed;
}


@font-face {
  font-family: 'Vonwaon';
  src: url('./assets/font/Vonwaon.ttf');
  font-weight: normal;
  font-style: normal;
}


</style>
