import request from "../utils/request";



//活动项目初始化
export const init = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "website/Login/init",
    method: "get",
    params: query,
  });
};

//增加访问量
export const visitAdd = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "website/Login/visitAdd",
    method: "post",
    data: query,
  });
};

//发送验证码
export const setCode = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "website/Login/sendingCode",
    method: "post",
    data: query,
  });
};

//注册账号
export const setRegisterCode = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "website/Login/registerCode",
    method: "post",
    data: query,
  });
};

//登录账号
export const verifyCode = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "website/Login/verifyCode",
    method: "post",
    data: query,
  });
};


//获取jsapi_ticket
export const wxTicket = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "website/Login/wx/ticket",
    method: "get",
    params: query,
  });
};


