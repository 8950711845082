import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: () => import('../views/Home/index'),
        meta: {
            title: "万人添照",
        },
    },
    {
        path: '/login',
        component: () => import('../views/Login/index'),
        meta: {
            title: "万人添照",
        },
    },
    {
        path: '/editName',
        component: () => import('../views/EditName/index'),
        meta: {
            title: "万人添照",
        },
    },
    {
        path: '/introduce',
        component: () => import('../views/Introduce/index'),
        meta: {
            title: "万人添照",
        },
    },
    {
        path: '/user',
        component: () => import('../views/User/index.vue'),
        meta: {
            title: "个人中心",
        },
    },
    {
        path: '/exhibit',
        component: () => import('../views/Exhibit/index'),
        meta: {
            title: "万人添照",
        },
    }
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`;
    const info = localStorage.getItem("info");
    const CompanyID = localStorage.getItem("CompanyID");
    
    if (!info && !CompanyID && to.path !== '/') {
        next('/');
    } else {
        let userInfo = localStorage.getItem("user");
        if (userInfo && JSON.parse(userInfo).Name.length == 0 && to.path != '/editName') {
            if (from.path != '/editName') {
                alert("请先完善笔名");
            }
            next('/editName');
        } else {
            next();
        }
        // next()
    }
})

export default router
